<template>

  <div class="updateProgress">
    <rxNavBar title="更新进度"></rxNavBar>
    <!--上传图片loading-->
    <loading v-if="loadingFlag"></loading>
    <van-tabs v-model="outerActive"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.2195121951219512rem"
    >
      <van-tab title="详情">
        <div class="firstTitle"><span>合同详情</span></div>
        <div class="container-normal detailInfo ">
          <div class="font-bold addLineHigh">地址：<span
              class="font-normal ">{{ initUpdateProgressData.contractInfo.dispatchAddress }}</span>
          </div>
          <div class="font-bold addLineHigh">合同编号：<span
              class="font-normal orangeColor">{{ initUpdateProgressData.contractInfo.contractCode }}</span>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left ">业主：<span
                class="font-normal ">{{ initUpdateProgressData.contractInfo.ownerName }}</span>
            </div>
            <div class="font-bold float-Right add-pos">面积：<span
                class="font-normal ">{{ initUpdateProgressData.contractInfo.builtUpArea + '㎡' }}</span>
            </div>
          </div>
          <div class="font-bold addLineHigh">收房年限：<span
              class="font-normal ">{{ initUpdateProgressData.contractInfo.contractYears + '年' }}</span>
          </div>
        </div>
        <div class="leftTitle"><span>申请详情</span></div>

        <div class="container-normal detailInfo">
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">申请人：<span
                class="font-normal ">{{ initUpdateProgressData.headList.applicant }}</span>
            </div>
            <div class="font-bold float-Right add-pos">联系方式：<span
                class="font-normal ">{{ initUpdateProgressData.headList.applicantMobile }}</span>
            </div>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">设计师：<span
                class="font-normal ">{{ initUpdateProgressData.headList.designGlobaluserName }}</span>
            </div>
            <div class="font-bold float-Right add-pos">类型：<span
                class="font-normal ">{{ initUpdateProgressData.headList.isFirst == '1' ? '首次' : '二次' }}</span>
            </div>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">装修预算：<span
                class="font-normal redColor">{{ initUpdateProgressData.headList.budget + '元' }}</span>
            </div>
            <div class="font-bold float-Right add-pos">交房日期：<span
                class="font-normal ">{{ initUpdateProgressData.headList.handoverDate }}</span>
            </div>
          </div>
          <div class="font-bold addLineHigh-auto" style="margin-top: 2px">装修需求：<span
              class="font-normal ">{{ initUpdateProgressData.headList.demand }}</span>
          </div>
          <div class="font-bold addLineHigh-auto">装修描述：<span
              class="font-normal  ">{{ initUpdateProgressData.headList.afterRentDesprition }}</span>
          </div>
        </div>
        <!--                v-if="imgList.length"-->
        <div class="container-imgs add-Margin-Top" v-if="imgList.length">
          <div class="swiper_show" ref="wrapperBox_house">
            <div class="content">
              <div class="imgWrap" v-for="(url,i) in imgList" @click="previewImg(i)">
                <van-image
                    width="90"
                    height="80"
                    fit="contain"
                    radius="10"
                    :src="url">
                </van-image>
                <div class="num">
                  {{ imgList.length }}图
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="leftTitle"><span>装配明细</span></div>
        <div class="innerTabs">
          <img class="tickletf" :src="innerActive==0?grassgreenTick:grayTickImg"/>
          <img class="tickRight" :src="innerActive==1?grassgreenTick:grayTickImg"/>
          <van-tabs v-model="innerActive"
                    line-width="20"
                    line-height="5"
                    title-active-color="rgb(255,81,45)"
                    swipeable
                    sticky
                    offset-top="1.2195121951219512rem"
          >
            <van-tab title="硬装方案">
              <div class="container-normal header detailInfo-inner">
                <div class="font-bold littlelineHigh">硬装负责人：<span
                    class="font-normal ">{{ initUpdateProgressData.hardChargeName }}</span>
                </div>
                <div class="font-bold littlelineHigh ">硬装首期占比：<span
                    class="font-normal ">{{ initUpdateProgressData.hardInpart }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计工期：<span
                    class="font-normal ">{{
                    initUpdateProgressData.hardAppointmentBeginTime
                  }} 至 {{ initUpdateProgressData.hardAppointmentEndTime }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计时长：<span
                    class="font-normal ">{{ initUpdateProgressData.handInquest + '天' }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计硬装费用：<span
                    class="font-normal ">{{ initUpdateProgressData.hardfeePrice + '元' }}</span>
                </div>
              </div>
              <div class="dividing-line"></div>

              <div class="container-line" style="display: flex;flex-flow: row nowrap">
                <span class="costTitle">实际硬装费用：</span>
                <!--                                若是未点完成显示的费用为预计费用-->
                <span class="font-bold redColor">{{sethardrealfeePrice}}</span>
                <!--                                {{initUpdateProgressData.hardRealFee}}-->
                <button class="hardFit-button" @click="heardFinish" v-if="!hardDisabled">硬装完成</button>
              </div>
              <div class="part-inputpart-row" @click="ishcStartShow = !ishcStartShow" v-if="!hardDisabled">
                <span :class=" !initUpdateProgressData.hardBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardBeginTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <div class="part-inputpart-row" v-if="hardDisabled">
                <span :class=" !initUpdateProgressData.hardBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardBeginTime | formatDateTime
                  }}</span>
              </div>
              <div>
                <van-popup v-model="ishcStartShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="starthcConfirm"
                      @cancel="starthcCancel"
                  />
                </van-popup>
              </div>
              <div class="part-inputpart-row" @click="ishcEndShow = !ishcEndShow" v-if="!hardDisabled">
                <span :class=" !initUpdateProgressData.hardEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardEndTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <div class="part-inputpart-row" v-if="hardDisabled">
                <span :class=" !initUpdateProgressData.hardEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardEndTime | formatDateTime
                  }}</span>
              </div>
              <div>
                <van-popup v-model="ishcEndShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="endhcConfirm"
                      @cancel="endhcCancel"
                  />
                </van-popup>
              </div>
              <div v-if="formUpdateProgressValidate.hardMapList.length > 0">
                <div v-for="(item,index)  in formUpdateProgressValidate.hardMapList" :key="index"
                     v-if="item.check">
                  <div class="dividing-line"></div>
                  <div class="container-line" @click="goodsListClick(index)">
                    <span class="goodTitle">{{ item.name }}</span>
                    <span class="goodsOfConfig">
                                        <span
                                            :style="{ color: inCpmpleteArrhc()[index]}">{{countConfigedhc()[index]
                                          }}</span>/{{ countAllhc()[index] }}项
                                    </span>
                    <div class="arrowBox" >
                      <img :class="hard_arrActive.includes(index)?'upArrowButton':'downArrowButton'"
                           src="../../assets/images/redyoujiantou.png">
                    </div>
                  </div>
                  <div class="infoContainer" v-show="hard_arrActive.includes(index)">
                    <div class="dividing-line"></div>
                    <table class="goodInfoTable">
                      <tr class="goodInfo-titleLine">
                        <td style="width:100px ">项目名称</td>
                        <td style="width:100px ">数量</td>
                        <!--                                            <td>装配区域</td>-->
                        <td style="width:100px " v-if="!hardDisabled">预计费用</td>
                        <td style="width:100px " v-if="hardDisabled">实际费用</td>
                        <td style="width:37px "></td>
                      </tr>
                      <tr style="height: 13px"></tr>
                      <tr v-for="(children,info_Index) in item.children"
                          v-show="formUpdateProgressValidate.hardCheckMap[children.id].checked"
                          :key="info_Index">
                        <td style="width:100px ">{{ children.itemName }}</td>
                        <td style="width:100px ">{{ formUpdateProgressValidate.hardCheckMap[children.id].count }}</td>
                        <td style="width:100px " class="costStyle">
                          <!--                                               <span v-show="formUpdateProgressValidate.hardCheckMap[children.id].realMoney>0" >￥</span><span :class="{'graycolor':!(formUpdateProgressValidate.hardCheckMap[children.id].realMoney>0)}">{{formUpdateProgressValidate.hardCheckMap[children.id].realMoney}}</span><span v-show="formUpdateProgressValidate.hardCheckMap[children.id].realMoney>0" >.00</span>-->
                          <span
                              :class="{'graycolor':costFormat(formUpdateProgressValidate.hardCheckMap[children.id].realMoney)=='未装配'}">{{hardDisabled ?
                              costFormat(formUpdateProgressValidate.hardCheckMap[children.id].realMoney) : costFormat(formUpdateProgressValidate.hardCheckMap[children.id].money)
                            }}</span>
                        </td>
                        <td style="width:37px " v-if="!hardDisabled"><img class="editImg"
                                                                          src="../../assets/images/Editing.png"
                                                                          @click="editProjecthc(index,info_Index)">
                        </td>
                        <td style="width:37px " v-if="hardDisabled">
                          <img class="editImg" src="../../assets/images/gxjdY.png">
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </van-tab>
            <van-tab title="软装方案">
              <div class="container-normal header detailInfo-inner">
                <div class="font-bold littlelineHigh">软装负责人：<span
                    class="font-normal ">{{ initUpdateProgressData.softChargeName }}</span>
                </div>
                <div class="font-bold littlelineHigh ">软装首期占比：<span
                    class="font-normal ">{{ initUpdateProgressData.softInpart }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计工期：<span
                    class="font-normal ">{{
                    initUpdateProgressData.softAppointmentBeginTime
                  }} 至 {{ initUpdateProgressData.softAppointmentEndTime }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计时长：<span
                    class="font-normal ">{{ initUpdateProgressData.softInquest + '天' }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计软装费用：<span
                    class="font-normal ">{{ initUpdateProgressData.softfeePrice + '元' }}</span>
                </div>
              </div>
              <div class="dividing-line"></div>
              <div class="container-line" style="display: flex;flex-flow: row nowrap">
                <span class="costTitle">实际软装费用：</span>
                <span class="font-bold redColor">{{ setsoftfeePrice }}</span>
                <!--                                {{initUpdateProgressData.softRealFee}}-->
                <button class="hardFit-button" v-if="!softDisabled" @click="softFinish">软装完成</button>
              </div>

              <div class="part-inputpart-row" @click="issoStartShow = !issoStartShow" v-if="!softDisabled">
                <span :class=" !initUpdateProgressData.softBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softBeginTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <div class="part-inputpart-row" v-if="softDisabled">
                <span :class=" !initUpdateProgressData.softBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softBeginTime | formatDateTime
                  }}</span>
              </div>
              <div>
                <van-popup v-model="issoStartShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="startsoConfirm"
                      @cancel="startsoCancel"
                  />
                </van-popup>
              </div>
              <div class="part-inputpart-row" @click="issoEndShow = !issoEndShow" v-if="!softDisabled">
                <span :class=" !initUpdateProgressData.softEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softEndTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <div class="part-inputpart-row" v-if="softDisabled">
                <span :class=" !initUpdateProgressData.softEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softEndTime | formatDateTime
                  }}</span>
              </div>
              <div>
                <van-popup v-model="issoEndShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="endsoConfirm"
                      @cancel="endsoCancel"
                  />
                </van-popup>
              </div>
              <div v-if="formUpdateProgressValidate.softMapList.length > 0">
                <div v-for="(item,idx) in formUpdateProgressValidate.softMapList" :key="idx"
                     v-if="item.check">
                  <div class="dividing-line"></div>
                  <div class="container-line" @click="soft_goodsListClick(idx)">
                    <span class="goodTitle">{{ item.name }}</span>
                    <span class="goodsOfConfig">
                                        <span
                                            :style="{ color: inCpmpleteArrso()[idx]}">{{countConfigedso()[idx]
                                          }}</span>/{{ countAllso()[idx] }}项
                                    </span>
                    <div class="arrowBox" >
                      <img :class="soft_arrActive.includes(idx)?'upArrowButton':'downArrowButton'"
                           src="../../assets/images/redyoujiantou.png">
                    </div>
                  </div>
                  <div class="infoContainer" v-show="soft_arrActive.includes(idx)">
                    <div class="dividing-line"></div>
                    <table class="goodInfoTable">
                      <tr class="goodInfo-titleLine">
                        <div>
                          <td style="width:100px ">项目名称</td>
                          <td style="width:100px ">数量</td>
                          <!--                                            <td>装配区域</td>-->
                          <td style="width:100px " v-if="!softDisabled">预计费用</td>
                          <td style="width:100px " v-if="softDisabled">实际费用</td>
                          <td style="width:37px "></td>
                        </div>
                      </tr>
                      <tr style="height: 13px"></tr>
                      <tr v-for="(children,info_idx)  in item.children" :key="info_idx"
                          v-if="formUpdateProgressValidate.softCheckMap[children.id] && formUpdateProgressValidate.softCheckMap[children.id].check">
                        <div v-for="(room,room_Index) in formUpdateProgressValidate.roomList"
                             v-show="formUpdateProgressValidate.softCheckMap[children.id][room.id].checked"
                             :key="room_Index">
                          <td style="width:100px">{{ children.itemName }} {{ room.roomName }}</td>
                          <td style="width:100px">
                            {{ formUpdateProgressValidate.softCheckMap[children.id][room.id].count }}
                          </td>
                          <td style="width:100px" class="costStyle">
                            <!--                                                <span v-show="info.cost>0" >￥</span><span :class="{'graycolor':!(info.cost>0)}">{{info.cost}}</span><span v-show="info.cost>0" >.00</span>-->
                            <!--                                                <span :class="{'graycolor':costFormat(info.cost)=='未装配'}">{{costFormat(info.cost)}}</span>-->
                            <span
                                :class="{'graycolor':costFormat(formUpdateProgressValidate.softCheckMap[children.id][room.id].realMoney)=='未装配'}">{{softDisabled ?
                                costFormat(formUpdateProgressValidate.softCheckMap[children.id][room.id].realMoney) : costFormat(formUpdateProgressValidate.softCheckMap[children.id][room.id].money)
                              }}</span>
                          </td>
                          <td style="width:37px " v-if="!softDisabled"><img class="editImg"
                                                                            src="../../assets/images/Editing.png"
                                                                            @click="editProjectso(idx,info_idx,room_Index)">
                          </td>
                          <td style="width:37px " v-if="softDisabled"><img class="editImg"
                                                                           src="../../assets/images/gxjdY.png"
                          >
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>

              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="leftTitle"><span>施工图片</span></div>
        <div class="part-inputpart">
          <div class="part-uploadpic-content" ref="wrapperBox_build">
            <van-uploader v-model="buildImg"
                          upload-icon="plus"
                          :max-count="20"
                          multiple
                          image-fit="contain"
                          :after-read="afterReadTest"
            >
            </van-uploader>
          </div>
        </div>
        <div class="leftTitle"><span>实际费用</span></div>
        <div class="container-cost ">
          <div class="costLine addTop ">
            <span class="font-bold float-Left ">硬装：</span>
            <div style="flex: auto"></div>
<!--            <input class="font-normal float-Right kd" style="border: none" :value="sethardrealfeePrice+'元'">-->
            <span class="font-normal float-Right kd" style="border: none">{{sethardrealfeePrice+'元'}}</span>
            <!--                            {{initUpdateProgressData.hardRealFee}}</span>-->
          </div>
          <div class="costLine addBottom">
            <span class="font-bold float-Left">软装：</span>
            <div style="flex: auto"></div>
<!--            <input class="font-normal float-Right kd" style="border: none" :value="setsoftfeePrice+'元'">-->
            <span class="font-normal float-Right kd" style="border: none">{{setsoftfeePrice+'元'}}</span>
            <!--                            ￥{{initUpdateProgressData.softRealFee}}</span>-->
          </div>
          <div class="dividing-line"></div>
          <div class="sumLine addBottom">
            <span class="font-bold float-Left">总实际费用：</span>
<!--            <input class="font-bold float-Right kd" style="border: none;height: 40px" :value="setTotalRealFee+'元'">-->
            <span class="font-bold float-Right kd" style="border: none;height: 40px">{{setTotalRealFee+'元'}}</span>
            <!--                            ￥{{initUpdateProgressData.totalRealFee}}</span>-->
          </div>
        </div>
        <div class="dividing-line"></div>
        <van-button class="saveButton_Enable" @click="saveUpdateProgressData">保存</van-button>
      </van-tab>
      <van-tab title="账单">
        <van-row style="margin-top: 10px"></van-row>
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="noContent ? '' : '已经到底了'"
              @load="onLoad"
          >
            <div class="panelBill">
              <div v-for="body in billList">
                <div style="width: 50%;float: left;padding: 16px 0px 3px 14px"><span
                    style="font-weight: 900;font-size: 15px">{{ body.billFeeType }}</span></div>
                <div style="text-align: right;padding: 16px 14px 3px 0px;position: relative;overflow: hidden">
              <span
                  style="font-weight: 900;font-size: 15px;color: #fe6641">{{
                  body.totalAmount > 0 ? '-' : ''
                }}{{ body.totalAmount }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" v-show="body.billDescription">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.billDescription }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" class="bordBottom">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.addTime }}</span>
                </div>
              </div>
            </div>
          </van-list>
          <common-empty v-if="noContent"></common-empty>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <div>
      <div class="getPwd"></div>
    </div>
    <!--        项目编辑框弹出-->
    <van-popup v-model="isEdit" position="bottom" @click-overlay="actualCost = ''">
      <div style="background-color: #FAFAFA; overflow: hidden">
        <div class="popupTitle"><span>项目详情</span></div>
        <div class="container-projectCost ">
          <div class="detailLine addTop ">
                        <span class="font-bold ">
                            {{ projectItem }}<span class="font-normal"> X </span><span
                            class="redColor">{{ projectItemCount }}</span>
                        </span>
          </div>
          <div class="detailLine addBottom">
            <span class="font-bold ">预算：</span>
            <span class="font-normal ">{{ budget }}.00元</span>
          </div>
        </div>
        <div style="display: flex;align-items: center">
          <div class="singleBox" style="display: flex;align-items: center">
            <span class="maker" :class="{'orangeColor':true}">• </span>
            <div class="blockTitle">实际费用</div>
            <div class="content-divide">|</div>
            <input type="number" class="inputPrice"
                   onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                   placeholder="输入金额"
                   v-model="actualCost">
            <div class="priceUnit">元</div>
          </div>
          <div style="margin-left: auto;display: flex;align-items: center" @click="sameBudget">
            <img class="samPic" :src="isSameAsExpected?orangeTickImg:grayTickImg">
            <div class="sameTip" :class="isSameAsExpected?'sameColor':'notSameColor'">与预算相同</div>
          </div>
        </div>
        <van-button class="saveProjectButton_Enable" @click="savePrice()">保存</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import {
  NavBar,
  Button,
  Tabs,
  Tab,
  Image as VanImage,
  ImagePreview,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Uploader,
  Popup,
  Divider,
  Col,
  Row,
  List, PullRefresh
} from 'vant';
import {base64Upload, browseBillList, showProgramme, updateProgressInfo,updateProgressNew} from "../../getData/getData";
import {getStaffId, globaluserId, responseUtil} from "../../libs/rongxunUtil";

Vue.use(Collapse);
Vue.use(CollapseItem);
import BScroll from "better-scroll";

export default {
  name: 'updateProgress',

  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [DatetimePicker.name]: DatetimePicker,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [Col.name]: Col,
    [Row.name]: Row,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    rxNavBar,
  },
  watch: {
    'initUpdateProgressData.softBeginTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.softEndTime && this.initUpdateProgressData.softEndTime <= newVal){
        responseUtil.alertMsg(this,'开始时间不能晚于结束时间')
        this.initUpdateProgressData.softBeginTime = oldVal
      }
    },
    'initUpdateProgressData.softEndTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.softBeginTime && this.initUpdateProgressData.softBeginTime >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.initUpdateProgressData.softEndTime = oldVal
      }
    },
    'initUpdateProgressData.hardBeginTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.hardEndTime && this.initUpdateProgressData.hardEndTime <= newVal){
        responseUtil.alertMsg(this,'开始时间不能晚于结束时间')
        this.initUpdateProgressData.hardBeginTime = oldVal
      }
    },
    'initUpdateProgressData.hardEndTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.hardBeginTime && this.initUpdateProgressData.hardBeginTime >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.initUpdateProgressData.hardEndTime = oldVal
      }
    }
  },
  data() {
    return {
      roleType:'1',   //登录人是否为装修负责人标识， 是 0   否 1
      isLoading: false,
      loadingFlag:false,//上传图片loading
      loading: false,
      finished: false,
      noContent: false,
      page: 1,
      billList: [],
      rtid: this.$route.query.rtid || '',
      hardDisabled: false,
      softDisabled: false,
      soBeginTime: '',
      soEndTime: '',
      typehs: '',
      currentDate: new Date(),
      //硬装起始日期
      ishcStartShow: false,
      //硬装截止日期
      ishcEndShow: false,
      //软装起始日期
      issoStartShow: false,
      //软装截止日期
      issoEndShow: false,
      index: '',
      info_Index: '',
      idx: '',
      info_idx: '',
      room_Index: '',
      initUpdateProgressData: {
        id: '',
        softchargeGlobaluser_id: '',
        softfeePrice: '',
        hardchargeGlobaluser_id: '',
        hardfeePrice: '',
        handInquest: '',
        softInquest: '',
        hardAppointmentBeginTime: '',
        hardAppointmentEndTime: '',
        softAppointmentBeginTime: '',
        softAppointmentEndTime: '',
        hardInpart: '',
        softInpart: '',
        firstHardfeePrice: '',
        firstSoftfeePrice: '',
        hardBeginTime: '',
        hardEndTime: '',
        hardRealFee: '',
        softBeginTime: '',
        softEndTime: '',
        softRealFee: '',
        budgetFee: '',
        totalRealFee: '',
        contractInfo: {},
        headList: {},
        imgList: [],
      },
      formUpdateProgressValidate: {
        id: '',
        doImgList: [],
        hardMapList: [],
        softMapList: [],
        roomList: [],
        finalSoftProgrammeMap: {},
        finalHandProgrammeMap: {},
        hardCheckMap: {},
        softCheckMap: {},
      },
      isEdit: false,
      projectItem: '',
      projectItemCount: '',
      budget: '',
      grassgreenTick: require('../../assets/images/GrassgreenTick.png'),
      orangeTickImg: require('../../assets/images/orangeTick.png'),
      grayTickImg: require('../../assets/images/grayTick.png'),
      actualCost: '',
      hard_arrActive: [],
      soft_arrActive: [],
      outerActive: 0,
      innerActive: 0,
      buildImg: [],
      imgList: []
    }
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return y + '-' + MM + '-' + d
    }
  },
  methods: {

    onRefresh() {
      this.billList = [];
      setTimeout(()=>{
        this.page = 1;
        this.finished = false
        this.loading = true
        this.noContent = false
        this.isLoading = false
        this.onLoad();
      },500)

    },

    onLoad() {
      let that = this
      let initdata = {}
      //员工id
      if(that.roleType == '1'){
        initdata.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initdata.user_id = globaluserId()
      }
      initdata.currentPage = that.page
      initdata.numberPage = 10
      // //租后id 可不填
      initdata.afterRent_id = that.$route.query.arid
      //套房源id 可不填
      if (that.$route.query.setHouse_id)
        initdata.setHouse_id = that.$route.query.setHouse_id
      browseBillList(initdata).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          let list = response.data.data.data
          // let count = response.data.data.pageCount
          if (list)
            that.billList = that.billList.concat(response.data.data.data)
          that.loading = false
          if (that.billList.length >= response.data.data.pageCount)
            that.finished = true
          if (1 == that.page && (!list || 0 == list.length))
            that.noContent = true
          else
            that.noContent = false
          that.page++;
        })
      })
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    setTotalRealFeee: function () {
      var hardfeePrice = 0
      for (let obj in this.formUpdateProgressValidate.hardCheckMap) {
        if (this.formUpdateProgressValidate.hardCheckMap[obj] && this.formUpdateProgressValidate.hardCheckMap[obj].checked) {
          var price = this.formUpdateProgressValidate.hardCheckMap[obj].realMoney ? this.formUpdateProgressValidate.hardCheckMap[obj].realMoney : '0'
          hardfeePrice = hardfeePrice + Number(price)
        }
      }
      var softfeePrice = 0
      for (let sobj in this.formUpdateProgressValidate.softCheckMap) {
        for (let ssubobj in this.formUpdateProgressValidate.softCheckMap[sobj]) {
          if (this.formUpdateProgressValidate.softCheckMap[sobj] && this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj] && this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].checked) {
            var prices = this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].realMoney ? this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].realMoney : '0'
            softfeePrice = softfeePrice + Number(prices)
          }
        }
      }
      this.initUpdateProgressData.totalRealFee = Number(hardfeePrice) + Number(softfeePrice)
      return this.initUpdateProgressData.totalRealFee
    },
    // 获取装修数据
    initRenovationData: function () {
      var that = this
      let queryData = {}
      if(that.roleType == '1'){
        queryData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        queryData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      queryData.rtid = that.rtid
      //  queryData.rtid = 64
      showProgramme(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.initUpdateProgressData.handInquest = response.data.data.headMap.hardAppointmentTimeLong
          that.initUpdateProgressData.softInquest = response.data.data.headMap.softAppointmentTimeLong

          that.initUpdateProgressData.softchargeGlobaluser_id = response.data.data.headMap.softChargeGlobaluser_id ? response.data.data.headMap.softChargeGlobaluser_id.toString() : ''
          that.initUpdateProgressData.softfeePrice = response.data.data.headMap.softBudgetFee
          that.initUpdateProgressData.hardchargeGlobaluser_id = response.data.data.headMap.hardChargeGlobaluser_id ? response.data.data.headMap.hardChargeGlobaluser_id.toString() : ''
          that.initUpdateProgressData.hardfeePrice = response.data.data.headMap.handBudgetFee

          that.initUpdateProgressData.hardChargeName = response.data.data.headMap.hardChargeName
          that.initUpdateProgressData.softChargeName = response.data.data.headMap.softChargeName

          that.initUpdateProgressData.hardAppointmentBeginTime = response.data.data.headMap.hardAppointmentBeginTime
          that.initUpdateProgressData.hardAppointmentEndTime = response.data.data.headMap.hardAppointmentEndTime
          that.initUpdateProgressData.softAppointmentBeginTime = response.data.data.headMap.softAppointmentBeginTime
          that.initUpdateProgressData.softAppointmentEndTime = response.data.data.headMap.softAppointmentEndTime

          that.initUpdateProgressData.hardBeginTime = response.data.data.headMap.hardBeginTime ? new Date(response.data.data.headMap.hardBeginTime) : ''
          that.initUpdateProgressData.hardEndTime = response.data.data.headMap.hardEndTime ? new Date(response.data.data.headMap.hardEndTime) : ''
          that.initUpdateProgressData.softBeginTime = response.data.data.headMap.softBeginTime ? new Date(response.data.data.headMap.softBeginTime) : ''
          that.initUpdateProgressData.softEndTime = response.data.data.headMap.softEndTime ? new Date(response.data.data.headMap.softEndTime) : ''

          that.initUpdateProgressData.hardRealFee = response.data.data.headMap.hardRealFee
          that.initUpdateProgressData.softRealFee = response.data.data.headMap.softRealFee

          that.initUpdateProgressData.pathList = response.data.data.pathList
          if (response.data.data.pathList && response.data.data.pathList.length){
            for (var i = 0; i < that.initUpdateProgressData.pathList.length; i++) {
              that.imgList.push(that.initUpdateProgressData.pathList[i].path)
            }
            that.$nextTick(() => {
              if (!that.scroll) {
                console.log(that.$refs)
                that.scroll = new BScroll(that.$refs.wrapperBox_house, {
                  scrollX: true,
                  eventPassthrough: 'vertical'
                })
              }
            })
          }

          that.initUpdateProgressData.hardInpart = response.data.data.headMap.hardFirstPhaseProportion
          that.initUpdateProgressData.softInpart = response.data.data.headMap.softFirstPhaseProportion
          that.initUpdateProgressData.firstHardfeePrice = response.data.data.headMap.firstHardfee
          that.initUpdateProgressData.firstSoftfeePrice = response.data.data.headMap.firstSoftfee

          that.initUpdateProgressData.budgetFee = response.data.data.headMap.budgetFee
          that.initUpdateProgressData.totalRealFee = response.data.data.headMap.totalRealFee

          that.initUpdateProgressData.headList = response.data.data.headMap
          that.initUpdateProgressData.contractInfo = response.data.data.contractInfo

          that.formUpdateProgressValidate.doImgList = response.data.data.doImgList //施工图片
          if (response.data.data.doImgList && response.data.data.doImgList.length){
            for (var j = 0; j < response.data.data.doImgList.length; j++) {
              that.buildImg.push({
                content: response.data.data.doImgList[j].path,
                url: response.data.data.doImgList[j].path
              })
            }
            // that.$nextTick(() => {
            //   if (!that.scroll2) {
            //     that.scroll2 = new BScroll(that.$refs.wrapperBox_build, {
            //       scrollX: true,
            //       eventPassthrough: 'vertical'
            //     })
            //   }
            // })
          }
          that.formUpdateProgressValidate.hardMapList = response.data.data.allHardList
          that.formUpdateProgressValidate.softMapList = response.data.data.allSoftList
          that.formUpdateProgressValidate.roomList = response.data.data.roomList
          that.formUpdateProgressValidate.finalHandProgrammeMap = response.data.data.selectedHardMap//同一个sql
          that.formUpdateProgressValidate.finalSoftProgrammeMap = response.data.data.selectedSoftMap//同一个sql

          that.formUpdateProgressValidate.hardCheckMap = that.initHardCheckMap(that.formUpdateProgressValidate.finalHandProgrammeMap, that.formUpdateProgressValidate.hardMapList)
          that.formUpdateProgressValidate.softCheckMap = that.initSoftCheckMap(that.formUpdateProgressValidate.finalSoftProgrammeMap, that.formUpdateProgressValidate.roomList, that.formUpdateProgressValidate.softMapList)
          //         console.log(that.formUpdateProgressValidate.hardCheckMap)
          // console.log(that.formUpdateProgressValidate.softCheckMap)
          if (response.data.data.headMap) {
            that.hardDisabled = response.data.data.headMap.hardFinishTime ? true : false
            that.softDisabled = response.data.data.headMap.softFinishTime ? true : false
          }
        })
      })
    },
    /**
     * 初始化chekbox的map
     * @param map
     */
    initHardCheckMap(map, list) {
      var result = {}
      for (let obj in list) {
        var subMap = list[obj] || {}
        var children = subMap['children'] || []
        var checkFlag = false
        for (var i = 0; i < children.length; i++) {
          var child = children[i] || {}
          if (child.id) {
            var map1 = {
              checked: map[child.id] ? true : false,
              id: map[child.id] && map[child.id].id ? map[child.id].id : '',
              count: map[child.id] && map[child.id].totalCount ? map[child.id].totalCount : 0,
              realMoney: map[child.id] && (map[child.id].realMoney || 0===map[child.id].realMoney) ? map[child.id].realMoney : (map[child.id] && (map[child.id].estimateMoney || 0===map[child.id].estimateMoney) ? map[child.id].estimateMoney : 0),
              money: map[child.id] && (map[child.id].estimateMoney || 0===map[child.id].estimateMoney) ? map[child.id].estimateMoney : 0,
            }
            result[child.id] = map1
            if (map[child.id] && !checkFlag) {
              checkFlag = true
            }
          }
        }
        subMap.check = checkFlag
      }
      return result
    },
    initSoftCheckMap(map, roomList, list) {

      var result = {}
      for (let obj in list) {
        var subMap = list[obj] || {}
        var children = subMap['children'] || []
        var checkAllFlag = false
        for (var i = 0; i < children.length; i++) {
          var child = children[i] || {}
          var roomList = roomList || []
          if (map[child.id]) {
            if (child.id) {
              result[child.id] = {}
            }
            var checkFlag = false
            for (var j = 0; j < roomList.length; j++) {
              var room = roomList[j] || {}
              if (room.id) {
                var map1 = {
                  checked: map[child.id][room.id] ? true : false,
                  id: map[child.id][room.id] && map[child.id][room.id].id ? map[child.id][room.id].id : '',
                  count: map[child.id][room.id] && map[child.id][room.id].totalCount ? map[child.id][room.id].totalCount : 0,
                  realMoney: map[child.id][room.id] && (map[child.id][room.id].realMoney || 0 === map[child.id][room.id].realMoney) ? map[child.id][room.id].realMoney : (map[child.id][room.id] && (map[child.id][room.id].estimateMoney || 0 === map[child.id][room.id].estimateMoney) ? map[child.id][room.id].estimateMoney : 0),
                  money: map[child.id][room.id] && (map[child.id][room.id].estimateMoney || 0 === map[child.id][room.id].estimateMoney) ? map[child.id][room.id].estimateMoney : 0,
                }
                result[child.id][room.id] = map1
                if (map[child.id][room.id] && !checkFlag) {
                  checkFlag = true
                }
              }
            }
            result[child.id].check = checkFlag
            if (checkFlag && !checkAllFlag) {
              checkAllFlag = true
            }
          }
        }
        subMap.check = checkAllFlag
      }
      return result
    },
    //与预算相同
    sameBudget() {
      if (this.typehs == 1) {
        this.actualCost = Number(this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[this.index].children[this.info_Index].id].money)
      } else {
        var room = this.formUpdateProgressValidate.roomList[this.room_Index];
        this.actualCost = Number(this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[this.idx].children[this.info_idx].id][room.id].money)
      }
    },
    //保存当前实际费用
    savePrice() {
      this.isEdit = false;
      if (this.typehs == 1) {
        this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[this.index].children[this.info_Index].id].realMoney = Number(this.actualCost)
        this.actualCost = ''
      } else {
        var room = this.formUpdateProgressValidate.roomList[this.room_Index];
        this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[this.idx].children[this.info_idx].id][room.id].realMoney = Number(this.actualCost)
        this.actualCost = ''
      }
      this.setTotalRealFeee();
    },
    editProjecthc(index, info_Index) {
      this.index = index;
      this.info_Index = info_Index;
      this.projectItem = this.formUpdateProgressValidate.hardMapList[index].children[info_Index].itemName;
      this.projectItemCount = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].count;
      this.budget = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].money
      this.actualCost = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].realMoney
      this.isEdit = true;
      this.typehs = 1;
    },
    editProjectso(idx, info_idx, room_Index) {
      this.idx = idx;
      this.info_idx = info_idx;
      this.room_Index = room_Index;
      let item = this.formUpdateProgressValidate.softMapList;
      var room = this.formUpdateProgressValidate.roomList[room_Index];
      this.projectItem = item[idx].children[info_idx].itemName + room.roomName
      this.projectItemCount = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].count;
      this.budget = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].money
      this.actualCost = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].realMoney
      this.isEdit = true;
      this.typehs = 2;
    },

    //物品配置图片多选上传
    afterReadTest(file){
      // this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];
          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },

    afterRead(file,i,fileLength) {
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      base64Upload(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          console.log(that.picList)
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },

    onClickLeft() {
    },
    goodsListClick(index) {
      let hash = this.hard_arrActive.findIndex(item => {
        return item == index
      })
      if (hash > -1) {
        this.hard_arrActive.splice(hash, 1)
      } else {
        this.hard_arrActive.push(index)
      }
    },
    soft_goodsListClick(index) {
      let hash = this.soft_arrActive.findIndex(item => {
        return item == index
      })
      if (hash > -1) {
        this.soft_arrActive.splice(hash, 1)
      } else {
        this.soft_arrActive.push(index)
      }
    },
    previewImg(start) {
      ImagePreview(
          {
            images: this.imgList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    previewBuildImg(start) {
      ImagePreview(
          {
            images: this.buildImg,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    clickStarthcShow() {
      this.ishcStartShow = !this.ishcStartShow;
      // var panel = document.getElementById('starthcPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.ishcStartShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    starthcConfirm(value) {
      var date = new Date(value)
      this.initUpdateProgressData.hardBeginTime = date
      this.ishcStartShow = false
    },
    //日期选择组件 点击取消键后执行方法
    starthcCancel() {
      this.ishcStartShow = false
    },
    clickEndhcShow() {
      this.ishcEndShow = !this.ishcEndShow;
      // var panel = document.getElementById('endhcPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.ishcEndShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    endhcConfirm(value) {
      var date = new Date(value)
      this.initUpdateProgressData.hardEndTime = date
      this.ishcEndShow = false
    },
    //日期选择组件 点击取消键后执行方法
    endhcCancel() {
      this.ishcEndShow = false
    },
    //软装日期
    clickStartsoShow() {
      this.issoStartShow = !this.issoStartShow;
      // var panel = document.getElementById('startsoPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.issoStartShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    startsoConfirm(value) {
      var date = new Date(value)
      this.soBeginTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      this.initUpdateProgressData.softBeginTime = date
      this.issoStartShow = false
    },
    //日期选择组件 点击取消键后执行方法
    startsoCancel() {
      this.issoStartShow = false
    },
    clickEndsoShow() {
      this.issoEndShow = !this.issoEndShow;
      // var panel = document.getElementById('endsoPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.issoEndShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    endsoConfirm(value) {
      var date = new Date(value)
      this.soEndTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      this.initUpdateProgressData.softEndTime = date
      this.issoEndShow = false
    },
    //日期选择组件 点击取消键后执行方法
    endsoCancel() {
      this.issoEndShow = false
    },
    //装修数据保存
    saveUpdateProgressData: function () {
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //  initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '2', '3']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      that.saveUpdateProgress(initData, false, '')
    },

    //硬装完成
    heardFinish: function () {
      if (!this.initUpdateProgressData.hardBeginTime || this.initUpdateProgressData.hardBeginTime == '') {
        responseUtil.alertMsg(this, '开始时间不能为空！')
        return
      }
      if (!this.initUpdateProgressData.hardEndTime || this.initUpdateProgressData.hardEndTime == '') {
        responseUtil.alertMsg(this, '结束时间不能为空！')
        return
      }
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '2']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      //  initData.user_id = 16
      that.saveUpdateProgress(initData, true, 'hard')
    },
    //软装完成
    softFinish: function () {
      if (!this.initUpdateProgressData.softBeginTime || this.initUpdateProgressData.softBeginTime == '') {
        responseUtil.alertMsg(this, '开始时间不能为空！')
        return
      }
      if (!this.initUpdateProgressData.softEndTime || this.initUpdateProgressData.softEndTime == '') {
        responseUtil.alertMsg(this, '结束时间不能为空！')
        return
      }
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '3']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      that.saveUpdateProgress(initData, true, 'soft')
    },
    //保存接口
    saveUpdateProgress: function (initData, flag, type) {
      var that = this
      updateProgressNew(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          if(200 == response.status){
            responseUtil.alertMsg(that, '操作成功')
            if (type == 'hard') {
              that.hardDisabled = true
            } else if (type == 'soft') {
              that.softDisabled = true
            }else{
              that.$router.go(-1)
            }
          }

          // that.initData()
          //that.$router.go(-1);
        })
      })
    },


  },
  computed:
      {
        //硬装实际合计费用
        sethardrealfeePrice() {
          var hardfeePrice = 0
          for (let obj in this.formUpdateProgressValidate.hardCheckMap) {
            if (this.formUpdateProgressValidate.hardCheckMap[obj] && this.formUpdateProgressValidate.hardCheckMap[obj].checked) {
              var price = this.formUpdateProgressValidate.hardCheckMap[obj].realMoney ? this.formUpdateProgressValidate.hardCheckMap[obj].realMoney : '0'
              hardfeePrice = hardfeePrice + Number(price)
            }
          }
          this.initUpdateProgressData.hardRealFeePrice = hardfeePrice
          return this.initUpdateProgressData.hardRealFeePrice
        },
        //软装预合计费用
        setsoftfeePrice() {
          var softfeePrice = 0
          for (let obj in this.formUpdateProgressValidate.softCheckMap) {
            for (let subobj in this.formUpdateProgressValidate.softCheckMap[obj]) {
              if (this.formUpdateProgressValidate.softCheckMap[obj] && this.formUpdateProgressValidate.softCheckMap[obj][subobj] && this.formUpdateProgressValidate.softCheckMap[obj][subobj].checked) {
                var price = this.formUpdateProgressValidate.softCheckMap[obj][subobj].realMoney ? this.formUpdateProgressValidate.softCheckMap[obj][subobj].realMoney : '0'
                softfeePrice = softfeePrice + Number(price)
              }
            }
          }
          this.initUpdateProgressData.softRealFeePrice = softfeePrice
          return this.initUpdateProgressData.softRealFeePrice
        },
        //总钱数
        setTotalRealFee() {
          var hardfeePrice = this.initUpdateProgressData.hardRealFeePrice ? this.initUpdateProgressData.hardRealFeePrice : 0
          var softfeePrice = this.initUpdateProgressData.softRealFeePrice ? this.initUpdateProgressData.softRealFeePrice : 0
          this.initUpdateProgressData.totalRealFee = Number(hardfeePrice) + Number(softfeePrice)
          return this.initUpdateProgressData.totalRealFee
        },

        isSameAsExpected() {
          if (this.budget == this.actualCost) {
            return true;
          }
          return false;
        },
        costFormat() {
          return function (value) {
            if (value >= 0) {
              return value + '.00元'
            }
            return '未装配';
          }
        },
        //硬装显示数量有0的每项前为红色
        inCpmpleteArrhc() {
          return function () {
            let countArr;
            let countAllArr;
            let redColorArr = [];
            // let  item = this.formUpdateProgressValidate.hardMapList;
            countArr = this.countConfigedhc();
            countAllArr = this.countAllhc();
            for (let t = 0; t < countArr.length; t++) {
              if (countArr[t] < countAllArr[t]) {
                redColorArr.push('red');
              } else {
                redColorArr.push('black')
              }
            }
            return redColorArr;
          }
        },
        //软装显示数量有0的每项前为红色
        inCpmpleteArrso() {
          return function () {
            let countArr;
            let countAllArr;
            let redColorArr = [];
            // let  item = this.formUpdateProgressValidate.softMapList;
            countArr = this.countConfigedso();
            countAllArr = this.countAllso();
            for (let t = 0; t < countArr.length; t++) {
              if (countArr[t] < countAllArr[t]) {
                redColorArr.push('red');
              } else {
                redColorArr.push('black')
              }
            }
            return redColorArr;
          }
        },
        //硬装显示有多少项有值的
        countConfigedhc() {
          return function () {
            let item = this.formUpdateProgressValidate.hardMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                if (typeof (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney) == "number") {
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney > 0) {
                    countTemp = countTemp + 1;
                    //console.log(countTemp)
                  }
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney <= 0) {
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //软装显示有多少项有值的
        countConfigedso() {
          return function () {
            let item = this.formUpdateProgressValidate.softMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                for (var k = 0; k < this.formUpdateProgressValidate.roomList.length; k++) {
                  var room = this.formUpdateProgressValidate.roomList[k] || {}
                  if (room.id && this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id]) {
                    if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id] && typeof (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney) == "number") {
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney > 0) {
                        countTemp = countTemp + 1;
                      }
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney <= 0) {
                      }
                    }
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //硬装显示总数值
        countAllhc() {
          return function () {
            let item = this.formUpdateProgressValidate.hardMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                if (typeof (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count) == "number") {
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count > 0) {
                    countTemp = countTemp + 1;
                    //console.log(countTemp)
                  }
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count <= 0) {
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //软装显示总数值
        countAllso() {
          return function () {
            let item = this.formUpdateProgressValidate.softMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                for (var k = 0; k < this.formUpdateProgressValidate.roomList.length; k++) {
                  var room = this.formUpdateProgressValidate.roomList[k] || {}
                  if (room.id && this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id]) {
                    if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id] && typeof (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count) == "number") {
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count > 0) {
                        countTemp = countTemp + 1;
                      }
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count <= 0) {
                      }
                    }
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        buttonEnable() {
          return (this.name.length > 0 && this.card_id.toString().length > 0 && this.bankOfDeposit.length > 0)
        },
        infoDisplayArr() {
          var displayArr = new Array(this.goodInfo.length);
          displayArr.fill(false);
          return displayArr;
        }
      },
  created() {
    //登录人是否为装修负责人标识， 是 0   否 1
    if(this.$route.query.roleType){
      this.roleType = this.$route.query.roleType;
    }
    // this.initBrowseBillList()
    this.initRenovationData()
    // this.$nextTick(() => {
    //   if (!this.scroll) {
    //     this.scroll = new BScroll(this.$refs.wrapperBox_house, {
    //       scrollX: true,
    //       eventPassthrough: 'vertical'
    //     })
    //     this.scroll = new BScroll(this.$refs.wrapperBox_build, {
    //       scrollX: true,
    //       eventPassthrough: 'vertical'
    //     })
    //
    //   }
    // })
  },
  mounted() {
    // countConfiged();
    // this.countConfiged();

  }

}
</script>


<style lang="less" scoped>
@outtab_textPadding: 13px;
@inertab_textPadding: 15px;
@text-padding-right: 48px;
@container_width: 8.8rem;

.panelBill {
  width: 100%;
  margin: 0px 0px;
  background-color: rgb(255, 255, 255);
  position: relative;
  font-size: 15px;
  text-align: left;
  overflow: hidden;
}

.bordBottom {
  border-bottom: 1px solid rgb(244, 244, 244);
}


.tickInPopUp {
  padding-right: 2px;
  padding-bottom: 2px;
  width: 15px;
  line-height: 34px;
}

.getPwdImg {
  //margin-top: 10px;
  margin-left: 22px;
  margin-top: 17px;
  float: left;
  width: 20px;
}

.getPwd {
  font-size: 14px;
  float: left;
  margin-top: 23px;
  margin-left: 4px;
  margin-bottom: 10px;
}

.container-cost {
  background-color: white;
  width: @container_width + 0.4;
  height: auto;
  margin: 0px auto 50px;
  border-radius: 10px;
  //padding: 17px 0px;
}

.container-projectCost {
  background-color: white;
  width: @container_width + 0.4;
  height: auto;
  margin: 0px auto 18px;
  border-radius: 10px;
  //padding: 17px 0px;
}

.costLine {
  &.addTop {
    padding-top: 17px;
  }

  &.addBottom {
    padding-bottom: 14px;
  }

  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 900;
  padding: 0px 20px;
  height: 23px;
}

.detailLine {
  &.addTop {
    padding-top: 12px;
  }

  &.addBottom {
    padding-bottom: 15px;
  }

  font-weight: 900;
  padding-left: 13px;
  //height: 25px;
  line-height: 16px;
}

.sumLine {
  color: rgb(244, 99, 76);
  font-weight: 900;
  padding: 0px 20px;
  height: 47px;
  line-height: 47px;
}

.arrowBox {
  position: absolute;
  right: 15px;
  top: 4px;
  margin-right: 5px;
}

.upArrowButton {
  width: 9px;
  transform: rotate(-90deg);
}

.downArrowButton {
  width: 9px;
  transform: rotate(90deg);
}

.editImg {
  width: 15px;
  display: flex;
}

.costStyle {
  text-align: center;
  //float: right;
  //padding-right: 15px;
}

.notSameColor {
  color: #989898;
}

.sameColor {
  color: #E05B42
}

.bluecolor {
  color: #3B98FF;
}

.goodTitle {
  padding-left: 15px;
  float: left;
  color: rgb(244, 99, 76);
  font-weight: 900;
}

.goodsOfConfig {
  color: black;
  margin-right: 65px;
  float: right;
  font-size: 16px;
  font-weight: 900;
}

.goodInfo-titleLine {
  font-size: 9px;
  background-color: #F4F9FF;
  height: 18px;
  padding-bottom: 15px;
  color: #a7adb3;
}

.innerTabs {
  position: relative;
  width: @container_width + 0.4;
  margin: 0 auto 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
  font-size: 15px;
  text-align: left;
  //overflow: hidden;
}

.tickletf {
  position: absolute;
  width: 15px;
  top: 15px;
  left: 122px;
  z-index: 2;
}

.tickRight {
  position: absolute;
  width: 15px;
  top: 15px;
  right: 29px;
  z-index: 2;
}

.dividing-line {
  //display: inline-block;
  height: 1px;
  width: 100%;
  margin: 0.1px 0;
  background-color: #e8e3e3;
}

// 图片墙
.swiper_show {

  width: @container_width;
  position: relative;
  margin: 8px 13px;
  height: 80px;

  .content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;

    .imgWrap {

      margin-right: 10px;
    }
  }

  .num {
    position: absolute;
    left: 8px;
    top: 12px;
    font-size: 10px;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    color: rgb(255, 255, 255);
    padding: 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: 900;
    line-height: 10px;
  }

}

.uploadWrap {

  //: 5px;
  //font-size: 14px;
}

.detailInfo {
  //position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  padding-left: @outtab_textPadding;
}

.detailInfo-inner {
  margin-top: 15px;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  // padding-left: @inertab_textPadding;
}

.add-Paddding-Right {
  padding-right: @text-padding-right;
}

.add-midPaddding-Right {
  padding-right: 75px;
}

.add-morePaddding-Right {
  padding-right: 102px;
}

.twoCol {
  position: relative;
}

.addLineHigh {
  height: 23px;
  line-height: 23px;
}

.addSmallineHigh {
  padding-left: 15px;
  height: 25px;
  line-height: 25px;
}

.littlelineHigh {
  padding-left: 15px;
  height: 22px;
  line-height: 22px;
}

.add-pos {
  position: absolute;
  left: 160px;
}

.addLineHigh-auto {
  height: auto;
  line-height: 22px;
}

.float-Left {
  float: left;
}

.float-Right {
  float: right;
}

.kd {
  width: 90px;
}

.font-bold {
  font-size: 14px;
  font-weight: bold;
}

.font-normal {
  font-size: 14px;
  font-weight: normal;
}

.costTitle {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.container-line {
  position: relative;
  // overflow: hidden;
  background-color: white;
  width: @container_width;
  margin: 0px auto;
  border-radius: 0px;
  height: 55px;
  line-height: 55px;

}

.goodInfoTable {
  text-align: center;
  border: none;
  border-spacing: 0px;
  /*width: 9.3rem;*/
  padding: 0px 0px 10px;

  tr:not(:first-child) {
    font-size: 15px;
    line-height: 26px;
  }
}

.infoContainer {
  background-color: white;
  width: 9.2rem;
  height: auto;
  margin: 0px auto;
}

.container-imgs {
  touch-action: none;
  overflow: hidden;
  background-color: white;
  width: @container_width + 0.4rem;
  height: auto;
  margin: 0px auto;
  border-radius: 10px;
}

.container-normal {
  overflow: hidden;
  background-color: white;
  width: @container_width;
  height: auto;
  margin: 0px auto;
  border-radius: 10px;

  .header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .norad {
    border-radius: 0px;
  }

  .foot {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.add-Margin-Top {
  margin-top: 20px;
}

.firstTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 10px;
}

.leftTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 30px;
}

.popupTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 18px;
}

.inputPrice {
  float: left;
  width: 35%;
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  font-weight: bold;
  border: none;
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  padding-left: 3px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  line-height: 47px;
}

.singleBox {
  background-color: white;
  width: 223px;
  height: 47px;
  border-radius: 0.26667rem;
  float: left;
  margin-left: 15px;
}

.maker {
  color: #999999;
  float: left;
  //padding-left: 14px;
  //padding-right: 8px;
  font-size: 28px;
  line-height: 46px;
}

.blockTitle {
  white-space: nowrap;
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
  line-height: 47px;
}

.blockContent {
  overflow: hidden;
  //float: left;
  font-size: 15px;
  font-weight: normal;
  width: 27%;
  line-height: 46px;
}

.content-divide {
  float: left;
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
  line-height: 47px;
}

.redColor {
  color: red;
}

.orangeColor {
  color: #ff3c00;
}

.graycolor {
  color: #bdbbbb;
}

.hardFit-button {
  margin-top: 13px;
  margin-right: 15px;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  height: 32px;
  line-height: 32px;
  width: auto;
  color: white;
  font-weight: 250;
  background: linear-gradient(to right, #7BC7FF 0px, #3B98FF 100%) repeat scroll 0% 0%;
}

.sameTip {
  line-height: 46px;
  font-size: 10px;
  float: right;
  margin-right: 22px;
}

.samPic {
  width: 15px;
  padding-right: 3px;
}

.saveButton_Enable {
  // background-color: rgba(184, 184, 184, 0.2);
  float: right;
  width: 100px;
  height: 1.1rem;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 70px;
  font-size: 15px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
}

.saveProjectButton_Enable {
  width: 9rem;
  height: 1.15rem;
  border-radius: 8px;
  margin-left: 18px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 15px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
}

input::-webkit-input-placeholder { /*Webkit browsers*/
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/*  Mozilla Firefox 4-18使用伪类 */
input:-moz-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/* Mozilla Firefox 19+ 使用伪元素  */
input::-moz-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/* IE10使用伪类 */
input:-ms-input-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-start {
  margin-left: 22px;
  float: left;
  font-weight: 900;
}

.part-end {
  margin-left: 22px;
  float: left;
  font-weight: 900;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
}

.part-inputpart-row-redtext {
  width: 100%;
  color: #ff5d3b;
}

.part-inputpart-row-startPeriod {
  line-height: 21px;
  /*padding-left: 120px;*/
}

.part-inputpart-row-right {
  float: right;
  margin-right: 23px;
}

.part-inputpart-row-right-timepick {
  width: 20px;
}

.part-inputpart-row-endPeriod {
  line-height: 21px;
  /*padding-left: 120px;*/
}

.part-inputpart-dropDownList {
  //height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.part-uploadpic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px;
  background-color: white;
  padding-top: 8px;
  border-radius: 8px;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  margin-top: 2px;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.part-inputpart-row-header {
  white-space: nowrap;
  font-weight: 900;
  width: 232px;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}
</style>
